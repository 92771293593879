import { memo } from "react";
import Alania from "../Components/10Alania";
import Support from "../Components/11Support";
import Question from "../Components/12Question";
import Header from "../Components/1Header";
import Live from "../Components/2Live";
import Infr from "../Components/3Infr";
import Price from "../Components/4Price";
import Summ from "../Components/5Summ";
import Obzor from "../Components/6Obzor";
import House from "../Components/7Dom";
import Stat from "../Components/8State";
import OneByOne from "../Components/9onebyone";
import Portfolio from "../Components/portfolio";

const Landing = memo(function Landing({ form, setForm, pop, setPop }) {
  return (
    <div>
      <Header form={form} setForm={setForm} pop={pop} setPop={setPop}></Header>
      <Portfolio></Portfolio>
      <Live form={form} setForm={setForm}></Live>

      <Infr form={form} setForm={setForm}></Infr>
      <Price form={form} setForm={setForm}></Price>
      {/* <Summ form={form} setForm={setForm}></Summ> */}
      <OneByOne form={form} setForm={setForm}></OneByOne>
      <Obzor form={form} setForm={setForm}></Obzor>
      <House form={form} setForm={setForm}></House>
      <Stat form={form} setForm={setForm}></Stat>

      <Alania form={form} setForm={setForm}></Alania>
      <Support form={form} setForm={setForm}></Support>
      <Question></Question>
    </div>
  );
});
export default Landing;
