import "./style.css";
import left from "./img/left.webp";
import palm from "./img/palm.webp";
import suums from "./img/suums.svg";
import mobik from "./img/mobik.svg";
export default function OneByOne({ form, setForm }) {
  return (
    <div className="oneBy" id="section5">
      <div className="oneContent">
        <img src={left} alt="" />
        <div className="oneContRight">
          <h3>
            <span>Наш кейс:</span> <br />
            ПРИОБРЕТЕНИЕ <br />
            ОДНОЙ <br /> КВАРТИРЫ 1+1
          </h3>
          <p>С ГАРАНТИРОВАННОЙ АРЕНДОЙ</p>
        </div>
      </div>
      <div className="oneNext">
        <img src={palm} alt="" />
        <div className="oneNextRight">
          <img src={document.body.clientWidth > 640 ? suums : mobik} alt="" />
        </div>
      </div>
    </div>
  );
}
