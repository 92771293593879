import './style.css';

import { Fade, Zoom } from 'react-reveal';
import { useEffect, useState } from 'react';

import InputMask from 'react-input-mask';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';
import at from './img/at.webp';
import book from './img/book.png';
import ded from './img/ded.webp';
import gal1 from './img/gal.svg';
import gal2 from './img/2gal.svg';
import kvi from './img/kvi.webp';
import palm from './img/palm.webp';
import plays from './img/play.svg';
import podl from './img/podl.webp';
import { useNavigate } from 'react-router-dom';

export default function Obzor({ form, setForm }) {
  const [stageKviz, setStageKviz] = useState(1);
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const Navigate = useNavigate();
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [answer5, setAnswer5] = useState('');
  const [anima, setAnima] = useState(false);
  function setQuiz() {
    if (tel.length < 10) {
      return;
    } else {
      setAnima(true);
      let form = new FormData();
      form.append('name', name);
      form.append('tel', tel);
      let quiz = '';
      if (localStorage.getItem('answers')) {
        {
          quiz = localStorage.getItem('answers');
        }
      }
      form.append('quiz', quiz);
      fetch('https://crproperty-app.ru/amocrm/webhook.php', {
        method: 'POST',
        body: JSON.stringify({
          phone: tel,
          url_main: 'https://turkey-pro.estate',
          name: name,
          answerQuiz: quiz,
          announcementUrl: document.location.href,
        }),
      }).then(response => {
        fetch('https://turkey-pro.estate/bot.php', {
          method: 'POST',
          body: form,
        }).then(response => {
          setTel('');
          setName('');
          setAnima(false);
          localStorage.setItem('answers', '');
          Navigate('/thanks');
        });
      });
    }
  }
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0,
    },
  };
  function checkAnswers(tip) {
    let answer = document.getElementsByName(tip);
    let num = 0;
    for (let i = 0; i < answer.length; i++) {
      if (answer[i].checked) {
        num += 1;
      }
    }
    if (num != 0) {
      return true;
    } else {
      return false;
    }
  }
  function updateAnswer() {
    if (stageKviz == 1) {
      if (checkAnswers('typeN')) {
        setStageKviz(stageKviz + 1);
      } else {
        return;
      }
    }
    if (stageKviz == 2) {
      if (checkAnswers('purpose')) {
        setStageKviz(stageKviz + 1);
      } else {
        return;
      }
    }
    if (stageKviz == 3) {
      if (checkAnswers('stage')) {
        setStageKviz(stageKviz + 1);
      } else {
        return;
      }
    }
    if (stageKviz == 4) {
      if (checkAnswers('price')) {
        setStageKviz(stageKviz + 1);
      } else {
        return;
      }
    }
    if (stageKviz == 5) {
      if (checkAnswers('purpose1')) {
        setStageKviz(stageKviz + 1);
      } else {
        return;
      }
    }

    if (stageKviz == 1) {
      let answer = document.getElementsByName('typeN');
      let arr = [];
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].checked) {
          arr.push(answer[i].value);
        }
      }
      let str = 'Подборка: ' + arr.join(', ');
      setAnswer1(str);
    }
    if (stageKviz == 2) {
      let answer = document.getElementsByName('purpose');
      let arr = [];
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].checked) {
          arr.push(answer[i].value);
        }
      }
      let str = 'Цель: ' + arr.join(', ');
      setAnswer2(str);
    }
    if (stageKviz == 3) {
      let answer = document.getElementsByName('stage');
      let arr = [];
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].checked) {
          arr.push(answer[i].value);
        }
      }
      let str = 'Стадии: ' + arr.join(', ');
      setAnswer3(str);
    }
    if (stageKviz == 4) {
      let answer = document.getElementsByName('price');
      let arr = [];
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].checked) {
          arr.push(answer[i].value);
        }
      }
      let str = 'Цена: ' + arr.join(', ');
      setAnswer4(str);
    }
    console.log(stageKviz);
    if (stageKviz == 5) {
      let answer = document.getElementsByName('purpose1');
      let arr = [];
      for (let i = 0; i < answer.length; i++) {
        if (answer[i].checked) {
          arr.push(answer[i].value);
        }
      }
      let str = 'Опции: ' + arr.join(', ');
      setAnswer5(str);
    }
  }
  useEffect(() => {
    let str = [answer1, answer2, answer3, answer4, answer5].join('; ');

    localStorage.setItem('answers', str);
  }, [answer1, answer2, answer3, answer4, answer5]);
  const [play, setPlay] = useState(false);
  return (
    <div className='Obzor' id='fsection'>
      <img src={palm} alt='' className='oPalm' />
      <h2>
        Сравнительный обзор <br /> недвижимости
      </h2>
      <div className='obzorContent'>
        <div className='obzorLeft'>
          <div className='expert'>от эксперта Николая Игнатова</div>
          <div className='ef'>
            {play && <YouTube videoId='Klfb34EK6bE' opts={opts} onReady={e => e.target.setVolume(100)} />}

            <img src={at} alt='' style={play ? { display: 'none' } : {}} />
            <img src={plays} alt='' onClick={() => setPlay(true)} className='play' style={play ? { display: 'none' } : {}} />
          </div>
        </div>
        <div className='obzorRight'>
          <div className='firstSp'>
            <h3>Квартира в Сочи </h3>
            <p>Центральный район</p>
            <ul>
              <li>
                <img src={gal1} alt='' />
                <p>Дом 18 этажей</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Дата постройки 2021 год</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Квартира 60 м2</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Огороженная территория</p>
              </li>
            </ul>
          </div>
          <div className='firstSp'>
            <h3>Квартира в Аланье</h3>

            <ul>
              <li>
                <img src={gal1} alt='' />
                <p>Огромная закрытая территория</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Малоэтажная застройка</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Инфраструктура отеля 5 звезд</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Открытый / закрытый зимний бассейн с подогревом</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Спортивные площадки: баскетбол, футбол и другие</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Теннисные корты</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Круглосуточный ресторан</p>
              </li>
              <li>
                <img src={gal1} alt='' />
                <p>Свои SPA салоны</p>
              </li>
            </ul>
          </div>
          <div className='pr' onClick={() => setForm(true)}>
            Получить консультацию от Николая
          </div>
        </div>
      </div>
      <div className='podl'>
        <div className='leftPodl'>
          <img src={podl} className='imgPodl' alt='' />
          <img src={book} className='bookPodl' alt='' />
          <img src={kvi} className='kvi' alt='' />
        </div>
        <div className='rightPodl'>
          <h4>
            Получите подборку <br />
            недвижимости
          </h4>
          {/*   <span>
             <br /> 
          </span> */}
          <p>по вашим критериям</p>
          {stageKviz == 1 && (
            <Fade left duration={700}>
              <div className='vibor'>
                <h4>Подборка из каких объектов вам интересна?</h4>
                <p>Ваш ответ поможет нам точнее подобрать список объектов</p>
                <div>
                  <input value={'Студии (1+0)'} type='checkbox' name='typeN' id='f1' />
                  <label htmlFor='f1'>Студии (1+0)</label>
                </div>
                <div>
                  <input value={'Квартиры и апартаменты (1+1, 2+1, 3+1, 4+1)'} type='checkbox' name='typeN' id='f2' defaultChecked />
                  <label htmlFor='f2'>Квартиры и апартаменты (1+1, 2+1, 3+1, 4+1)</label>
                </div>
                <div>
                  <input value={'Пентхаусы'} type='checkbox' name='typeN' id='f3' />
                  <label htmlFor='f3'>Пентхаусы</label>
                </div>
                <div>
                  <input value={'Дома и виллы'} type='checkbox' name='typeN' id='f4' />
                  <label htmlFor='f4'>Дома и виллы</label>
                </div>
                <div>
                  <input value={'Коммерческая недвижимость и земля'} type='checkbox' name='typeN' id='f5' />
                  <label htmlFor='f5'>Коммерческая недвижимость и земля</label>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz == 2 && (
            <Fade right duration={700}>
              <div className='vibor'>
                <h4>С какой целью планируете приобрести недвижимость?</h4>
                <p>Ваш ответ поможет нам точнее подобрать список объектов</p>
                <div>
                  <input value={'Личное проживание (постоянное или сезонное)'} type='checkbox' name='purpose' id='a1' />
                  <label htmlFor='a1'>Личное проживание (постоянное или сезонное)</label>
                </div>
                <div>
                  <input value={'Сдача в аренду'} type='checkbox' name='purpose' id='a2' />
                  <label htmlFor='a2'>Сдача в аренду</label>
                </div>
                <div>
                  <input value={'Инвестиция в недвижимость'} type='checkbox' name='purpose' id='a3' defaultChecked />
                  <label htmlFor='a3'>Инвестиция в недвижимость</label>
                </div>
                <div>
                  <input value={'Получение ВНЖ и гражданства'} type='checkbox' name='purpose' id='a4' />
                  <label htmlFor='a4'>Получение ВНЖ и гражданства</label>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz == 3 && (
            <Fade left duration={700}>
              <div className='vibor'>
                <h4>Объекты на какой стадии вам интересны?</h4>
                <p>Ваш ответ поможет нам точнее подобрать список объектов</p>
                <div>
                  <input value={'Готовые к заселению – Новостройки'} type='checkbox' name='stage' id='s1' defaultChecked />
                  <label htmlFor='s1'>Готовые к заселению – Новостройки</label>
                </div>
                <div>
                  <input value={'Готовые к заселению – Вторичная недвижимость'} type='checkbox' name='stage' id='s2' />
                  <label htmlFor='s2'>Готовые к заселению – Вторичная недвижимость</label>
                </div>
                <div>
                  <input value={'На стадии строительства – Со сдачей в ближайшее время'} type='checkbox' name='stage' id='s3' />
                  <label htmlFor='s3'>На стадии строительства – Со сдачей в ближайшее время</label>
                </div>
                <div>
                  <input value={'На стадии строительства – Со сдачей в 2024 г. и позже'} type='checkbox' name='stage' id='s4' />
                  <label htmlFor='s4'>На стадии строительства – Со сдачей в 2024 г. и позже</label>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz == 4 && (
            <Fade right duration={700}>
              <div className='vibor'>
                <h4>На какой бюджет вы ориентируетесь?</h4>
                <p>Ваш ответ поможет нам точнее подобрать список объектов</p>
                <div>
                  <input value={'До 120.000 €'} type='checkbox' name='price' id='e1' />
                  <label htmlFor='e1'>До 120.000 €</label>
                </div>
                <div>
                  <input value={'120.000 € - 200.000 €'} type='checkbox' name='price' id='e2' defaultChecked />
                  <label htmlFor='e2'>120.000 € - 200.000 €</label>
                </div>
                <div>
                  <input value={'200.000 € - 500.000 €'} type='checkbox' name='price' id='e3' />
                  <label htmlFor='e3'>200.000 € - 500.000 €</label>
                </div>
                <div>
                  <input value={'Более 500.000 €'} type='checkbox' name='price' id='e4' />
                  <label htmlFor='e4'>Более 500.000 €</label>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz == 5 && (
            <Fade left duration={700}>
              <div className='vibor'>
                <h4>Какие из опций могли бы быть вам интересны?</h4>
                <p>Ваш ответ поможет нам точнее подобрать список объектов</p>
                <div className='flexIn'>
                  <div>
                    <div>
                      <input value={'Онлайн-сделка'} type='checkbox' name='purpose1' id='j1' defaultChecked />
                      <label htmlFor='j1'>Онлайн-сделка</label>
                    </div>
                    <div>
                      <input value={'Рассрочка'} type='checkbox' name='purpose1' id='j2' />
                      <label htmlFor='j2'>Рассрочка</label>
                    </div>
                    <div>
                      <input value={'Оплата рублями из России'} type='checkbox' name='purpose1' id='j3' />
                      <label htmlFor='j3'>Оплата рублями из России</label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input value={'Выездной тур'} type='checkbox' defaultChecked name='purpose1' id='j4' />
                      <label htmlFor='j4'>Выездной тур</label>
                    </div>
                    <div>
                      <input value={'Онлайн-просмотр'} type='checkbox' name='purpose1' id='j5' />
                      <label htmlFor='j5'>Онлайн-просмотр</label>
                    </div>
                    <div>
                      <input value={'Оплата  криптовалютой'} type='checkbox' name='purpose1' defaultChecked id='j6' />
                      <label htmlFor='j6'>
                        Оплата <br /> криптовалютой
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz == 6 && (
            <Fade left duration={700}>
              <div className='vibor'>
                <h4>Отправляем Вам подборку недвижимости</h4>
                <div className='lastWord'>
                  <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder='ВАШЕ ИМЯ' />
                  <InputMask
                    mask='+9 999 999 99 999999'
                    className='tele'
                    maskChar={null}
                    value={tel}
                    onChange={e => setTel(e.target.value)}
                    placeholder='+7 999 888 88 88*'
                  />
                  <div
                    className='sdq'
                    onClick={() => setQuiz()}
                    style={
                      anima
                        ? {
                            animation: 'bord 2s infinite',
                            pointerEvents: 'none',
                          }
                        : {}
                    }
                  >
                    {anima ? 'пожалуйста подождите' : 'отправить'}
                  </div>
                </div>
              </div>
            </Fade>
          )}
          {stageKviz != 6 && (
            <div className='quiz_buttons'>
              <div className='back' onClick={() => stageKviz != 1 && setStageKviz(stageKviz - 1)}>
                Назад
              </div>

              <div className='next' onClick={() => stageKviz != 6 && updateAnswer()}>
                Далее
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
