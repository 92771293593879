import "./style.css";
import i1 from "./img/1.webp";
import i2 from "./img/2.webp";
import i3 from "./img/3.webp";
import Zoom from "react-reveal/Zoom";
import Marquee from "react-fast-marquee";
export default function Price({ form, setForm }) {
  return (
    <div className="Price" id="section2">
      <h2>Стоимость</h2>
      <h3>недвижимости</h3>{" "}
      <Zoom left cascade>
        <p>в Москве, Сочи, Дубае и Турции</p>{" "}
      </Zoom>{" "}
      <div className="train">
        <img src={i3} alt="" />
        <img src={i2} alt="" />
        <img src={i1} alt="" />
      </div>
    </div>
  );
}
