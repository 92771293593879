import "./style.css";
import car from "./img/car.svg";
import nik from "./img/nk.png";
import ex from "./img/met.svg";
import gal from "./img/gal.svg";
import mob from "./img/mob.png";
export default function Support({ form, setForm }) {
  return (
    <div className="support" id="section6">
      <img src={nik} className="kolk" alt="" />
      <h3>
        Полное <br /> сопровождение
      </h3>
      <p>
        <span>от выбора до вселения </span>в свою недвижимость
      </p>
      <div className="thf">
        <b>3000</b>
        <p>
          постоянно обновляемых объектов
          {document.body.clientWidth > 640 && <br />}
          <span> напрямую от застройщиков</span>
        </p>
      </div>
      <div className="wtf">
        <div className="grLine"></div>
        <div className="par">
          <div>
            <img src={gal} alt="" />
            <p>
              При покупке <b>2-3 и более объектов</b> – <br />
              специальные предложения от застройщиков.
            </p>
          </div>
          <div>
            <img src={gal} alt="" />
            <p>
              <b>От 30% - первый взнос</b> с выбором <br /> суммы и графика
              платежей
            </p>
          </div>
        </div>
      </div>
      {document.body.clientWidth < 640 && <img className="mobS" src={mob} />}
      <div className="sssopr">
        <img src={ex} className="ex" alt="" />
        <img src={car} className="cor" alt="" />
        <h4>
          Полное юридическое <br /> сопровождение:
        </h4>
        <div className="flex">
          <ul>
            <li>
              <img src={gal} alt="" />
              <p>Получение экспертной оценки объекта</p>
            </li>
            <li>
              <img src={gal} alt="" />
              <p>Получение налогового номера</p>
            </li>
            <li>
              <img src={gal} alt="" />
              <p>Открытие банковского счета</p>
            </li>
            <li>
              <img src={gal} alt="" />
              <p>Нотариальное оформление договора</p>
            </li>
          </ul>
          <ul>
            <li>
              <img src={gal} alt="" />
              <p>Регистрация договора</p>
            </li>
            <li>
              <img src={gal} alt="" />
              <p>Гарантия чистоты сделки</p>
            </li>
            <li>
              <img src={gal} alt="" />
              <p>
                Помощь с оформлением ВНЖ <br /> и гражданства
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
