import "./style.css";
import f1 from "./img/f1.svg";
import f2 from "./img/f2.svg";
import f3 from "./img/f3.svg";
import f4 from "./img/f4.svg";
import f5 from "./img/f5.svg";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import { Slider } from "infinite-react-carousel";
export default function House({ form, setForm }) {
  return (
    <div className="house" id="section3">
      <h3>
        Преимущества <br /> приобретения
      </h3>
      <p>недвижимости в Турции</p>
      <div className="houseImg">
        <img src={f1} alt="" />
        <img src={f2} alt="" />
      </div>
      <div className="houseImg iui">
        <img src={f3} alt="" />
        <img src={f4} className="houseAdd" alt="" />
        <img src={f5} alt="" />
      </div>
      {document.body.clientWidth < 640 && (
        <div className="sliderNew">
          <Slider dots arrows={false} duration={80}>
            <img src={i1} alt="" />
            <img src={i2} alt="" />
            <img src={i3} alt="" />
            <img src={i4} alt="" />
            <img src={i5} alt="" />
          </Slider>
        </div>
      )}
      <div className="ekz">
        <div>
          <p>
            Заказать бесплатную <br />
            экспертизу недвижимости в Турции
          </p>
          <span>
            Вы получите на руки PDF документ со всеми параметрами недвижимости,
            её аналитикой
          </span>
        </div>
        <div className="button" onClick={() => setForm(true)}>
          Заказать
        </div>
      </div>
    </div>
  );
}
